<div class="w-full min-h-svh flex flex-col md:flex-row justify-between">
  <div class="flex-1 bg-black flex flex-col p-8">
    <img src="../../../assets/images/logo_white.png" class="w-32" alt="" />

    <div *ngIf="plan" class="flex flex-col my-8">
      <h6 class="text-2xl font-medium text-gray-400">
        Assinar Plano {{ plan.name }}
      </h6>

      <div class="flex flex-nowrap space-x-2 items-center">
        <h5 class="text-4xl font-medium text-white">
          {{ planPriceFormatted }}
        </h5>
        <div class="flex flex-col justify-start">
          <span class="text-sm text-gray-400 leading-none"> por</span>
          <span class="text-sm text-gray-400 leading-none">
            {{ period === "monthly" ? "mês" : "ano" }}
          </span>
        </div>
      </div>

      <div class="flex flex-col w-4/5 my-10">
        <form [formGroup]="checkoutForm">
          <div class="flex mb-4" *ngIf="period === 'monthly'">
            <div class="form-control">
              <label class="cursor-pointer">
                <span class="label-text text-white mr-4"
                  >Ativar assinatura recorrente</span
                >
                <input
                  formControlName="recurrent"
                  type="checkbox"
                  class="checkbox checkbox-primary checkbox-sm"
                />
              </label>
            </div>
          </div>

          <div class="flex mb-4" *ngIf="recurrent">
            <div class="form-control">
              <label>
                <span style="color: gray">
                  Para a opção de pagamento recorrente, apenas o método de
                  cartão de crédito está disponível.
                </span>
              </label>
            </div>
          </div>

          <div class="flex mb-4" *ngIf="!recurrent">
            <div class="form-control">
              <label class="cursor-pointer">
                <span class="label-text text-white mr-4">
                  Quantos meses deseja comprar?
                </span>
                <input
                  min="1"
                  max="{{ maxNumberPayments }}"
                  formControlName="number_payments"
                  type="number"
                  class="input input-sm"
                />
              </label>
            </div>
          </div>
        </form>

        <div
          class="flex w-full justify-between items-center font-medium text-white mb-4"
        >
          <span>Plano Privilege</span>
          <span>{{ planPriceFormatted }}</span>
        </div>

        <div
          class="flex w-full justify-between items-center font-medium text-white mb-4"
        >
          <span class="">Total a pagar</span>
          <span *ngIf="couponDiscount == 0">
            {{ totalToPayFormatted }}
          </span>
          <span *ngIf="couponDiscount > 0">
            <s>{{ totalToPayFormatted }}</s>
          </span>
        </div>

        <div
          class="flex w-full justify-between items-center font-medium text-white mb-4"
          *ngIf="couponDiscount > 0"
        >
          <span class="">Desconto</span>
          <span>{{ couponDiscountFormatted }}</span>
        </div>

        <div
          class="flex w-full justify-between items-center font-medium text-white mb-4"
          *ngIf="totalWithDiscount > 0"
        >
          <span class="">Total com desconto</span>
          <span>{{ totalWithDiscountFormatted }}</span>
        </div>

        <div class="w-full flex space-x-2 mt-4">
          <input
            type="text"
            class="input input-sm input-ghost input-bordered w-full bg-white"
            placeholder="Possui um cupom?"
            [(ngModel)]="couponCode"
          />
          <button
            class="btn btn-success btn-sm"
            (click)="setCoupon()"
            [disabled]="couponCode.length == 0"
          >
            Adicionar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="flex-1 bg-white flex flex-col py-8 px-4 md:px-40">
    <h6 class="text-2xl font-medium text-gray-700">Formas de pagamento</h6>
    <div role="tablist" class="tabs tabs-boxed">
      <a
        (click)="activeTab('credit_card')"
        [ngClass]="{ 'tab-active': currentTab === 'credit_card' }"
        role="tab"
        class="tab"
      >
        Cartão
      </a>
      <a
        (click)="activeTab('pix')"
        [ngClass]="{
          'tab-active': currentTab === 'pix',
          'tab-disabled': recurrent
        }"
        role="tab"
        class="tab"
      >
        PIX
      </a>
      <a
        (click)="activeTab('boleto')"
        [ngClass]="{
          'tab-active': currentTab === 'boleto',
          'tab-disabled': recurrent
        }"
        role="tab"
        class="tab"
      >
        Boleto
      </a>
    </div>
    <div class="w-full" *ngIf="currentTab === 'credit_card'">
      <app-credit-card-subscription-form
        [planId]="id"
        [period]="period"
        [values]="checkoutForm.value"
      ></app-credit-card-subscription-form>
    </div>

    <div class="w-full" *ngIf="currentTab === 'pix'">
      <app-pix-subscription-form
        [plan_id]="id"
        [period]="period"
        [values]="checkoutForm.value"
      ></app-pix-subscription-form>
    </div>

    <div class="w-full" *ngIf="currentTab === 'boleto'">
      <app-boleto-subscription-form
        [plan_id]="id"
        [period]="period"
        [values]="checkoutForm.value"
      ></app-boleto-subscription-form>
    </div>
  </div>
</div>
